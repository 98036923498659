<template>
  <div>
    <!-- Title and icon for the commit history section -->
    <!-- Select branch dropdown -->
    <div class="row">
      <div class="col-lg-12"></div>
    </div>
    <!-- Commit history table section -->
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="card-body">
            <!-- Table header options -->
            <div class="row">
              <div class="col-md-2 mb-2">
                <!-- Dropdown to select a branch from 'repobranchesdata' -->
                <select
                  class="form-control custom-branch-select small-branch-button"
                  v-model="selected"
                  @change="(e) => getBranchCommit(e.target.value)"
                  style="background-color: #cfdee9"
                >
                  <option value="" disabled>Select Branch</option>
                  <option
                    v-for="branch in repobranchesdata"
                    :key="branch.value"
                    :value="branch.value"
                  >
                    <span class="mdi mdi-note-text"></span>{{ branch.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-5 mb-2">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-flex align-items-center"
                    ><span class="text-nowrap">Show entries</span>
                    <div class="col-md-3">
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col-md-5 mb-2 d-flex justify-content-end">
                <!-- Input field for searching commit messages -->
                <div class="col-md-right">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search  by commit message"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- Table displaying commit history -->
            <div class="table-responsive mb-0">
              <!-- b-table component with various properties -->
              <b-table
                :items="repocommitdata"
                :fields="colfields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                :filter-function="filterTable"
                ref="commitTable"
                stacked="md"
                show-empty
                small
              >
                <!-- Custom cell for the 'date' column -->
                <template v-slot:cell(commit.author.date)="row">
                  <span>{{ formatDate(row.item.commit.author.date) }}</span>
                </template></b-table
              >
            </div>
            <!-- Pagination and row count information -->
            <div class="row">
              <div
                class="dataTables_paginate paging_simple_numbers col justify-content-center row-count-cust-cls"
              ></div>
              <div class="row-pagination-cust-cls">
                <ul class="pagination-rounded mb-0">
                  <!-- Pagination controls -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import moment from "moment";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api/global.env";
export default {
  page: {
    title: "metR",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Repository Commits",
      items: [
        {
          text: "Repo Commits",
          href: "/",
        },
        {
          text: "Basic",
          active: true,
        },
      ],
      repodata: [],
      repocommitdata: [],
      repobranchesdata: [],
      repobranchcommitdata: [],
      reponamemodal: "",
      apiURL: process.env.VUE_APP_DATABASEURL,
      currentUser: this.$store.state.Auth.userId,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "published_at",
      sortDesc: true,
      colfields: [
        {
          key: "commit.message",
          label: "Commit Message",
          sortable: false,
        },
        {
          key: "author.login",
          label: "Committed By",
          sortable: false,
        },
        {
          key: "commit.author.date",
          label: "Date and Time",
          sortable: false,
        },
      ],
      selected: "main",
      projectData: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.repocommitdata.length;
    },
  },
  mounted() {
    this.getProjectDetails();
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    // Method to format the date using Moment.js
    formatDate(date) {
      console.log("date:", date);
      return moment(date).format("DD-MMM-YYYY hh:mm A");
    },
    moment,
    // Get Selected Branch
    // getSelectedItem: function (myarg) {
    //   this.getBranchCommit(myarg);
    // },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getBranches() {
      this.$store.getters.client
        .get(
          `orgadmin/repoBranches?repoUser=${this.projectData.owner}&repoName=${this.projectData.projectName}`
        )
        .then((response) => {
          response.data.forEach((element) => {
            this.repobranchesdata.push({
              value: element.name,
              text: element.name,
            });
          });
        });
    },
    getCommit() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(
          `orgadmin/repoCommit?repoUser=${this.projectData.owner}&repoName=${this.projectData.projectName}`
        )
        .then((response) => {
          console.log("in get commmit: ", response.data);
          this.repocommitdata = response.data;
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
    getBranchCommit(branchname) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(
          `orgadmin/repocommit/branch?repoUser=${this.projectData.owner}&repoName=${this.projectData.projectName}&branchName=${branchname}`
        )
        .then((response) => {
          this.repocommitdata = response.data;
          this.$refs.commitTable.refresh();
          this.$refs["commitTable"].refresh();
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
    async getProjectDetails() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(
          `project/byprojectid?projectId=${CryptoJS.AES.decrypt(
            this.$route.params.id,
            secretKey
          ).toString(CryptoJS.enc.Utf8)}`
        )
        .then((res) => {
          let projectdata1 = res.data;
          if (projectdata1.length > 0) {
            this.projectData = res.data[0];
            this.title = res.data[0].projectName;
          }
        })
        .then(() => {
          this.getBranches();
          this.getCommit();
          loader.hide();
        })
        .catch(() => {});
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.commit.message
        .toUpperCase()
        .includes(filterUpperCase);
      return hasNameMatch;
    },
  },
};
</script>
<style scoped>
label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.drpdwn-cls {
  background: #e0e0e0 !important;
}
.drpdwn-cls > button:hover {
  color: #505d69 !important;
  border-color: #e7e7e8 !important;
}
.drpdwn-cls > .btn-primary:not(:disabled):not(.disabled):active,
.drpdwn-cls > .btn-primary:not(:disabled):not(.disabled).active,
.drpdwn-cls.show > .btn-primary.dropdown-toggle {
  background: #e0e0e0 !important;
  border-color: #e7e7e8;
  color: #838588;
}
.drpdwn-cls > .btn-primary:focus,
.drpdwn-cls > .btn-primary.focus {
  color: #838588;
  border-color: #e7e7e8;
}
.small-branch-button {
  font-size: 12px;
  /* Adjust the font size as needed */
  padding: 0.25rem 0.5rem;
  /* Adjust the padding as needed */
}
@media (max-width: 767px) {
  #tickets-table_filter .form-control::placeholder {
    font-size: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }
  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
